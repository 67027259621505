body {
    margin: 0;
    font-family: -apple-system, "Inter", sans-serif!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --primary: #0f75f5;
    --success: #3caa62;
    --danger: #c2242c;
}

.text-danger {
    color: #f32727;
}

.dx-overlay-wrapper {
    font-family: -apple-system, "Inter", sans-serif!important;
    font-size: 12px!important;
}

.dx-widget input,
.dx-widget textarea {
    font-family: -apple-system, "Inter", sans-serif!important;
}

button {
    font-family: -apple-system, "Inter", sans-serif!important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dx-widget {
    font-family: -apple-system, "Inter", sans-serif!important;
}

.dx-swatch-additional .dx-widget {
    font-size: 12.5px!important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: -apple-system, "Inter", sans-serif!important;
    line-height: 1.28571429em;
    margin: calc(2rem - .14285714em) 0 1rem;
    font-weight: 700;
    padding: 0;
}

.loading-login {
    width: 100px;
    height: 100px;
    margin-top: 45vh;
    margin-left: auto;
    margin-right: auto;
}

.Toastify__toast {
    font-family: -apple-system, "Inter", sans-serif!important;
}

.toolbar-bg {
    background-color: #FAFAFA!important;
    padding-right: 1rem!important;
}

.body-container .dx-drawer-content {
    background-color: #fafafa;
}

.padding-top-1rem {
    padding-top: 1rem;
}

.padding-left-2rem {
    padding-left: 2rem;
}

.bg-background {
    /* background-color:#FAFAFA!important; */
    background-color: transparent!important;
}

.form-group {
    margin-bottom: 1rem;
}

.form-label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.form-help-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}

.form-inline {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.form-inline .form-group {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.form-inline .form-label {
    margin-right: 5px;
    margin-bottom: 0px;
}

.pop-up-form {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.hidden {
    display: none;
}

.numberbox-text-center input {
    text-align: center;
}

.page-toolbar-filter {
    font-size: 1.3rem;
    font-weight: 500;
}

.page-toolbar {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.page-caption {
    font-weight: 500;
    color: black;
    font-size: 1.3rem;
}

.my-animation-popup .dx-toolbar-label {
    max-width: 100%!important;
}

.p-tabs {
    display: flex;
    justify-content: center;
}

.p-tabs> :nth-of-type(1) {
    border-radius: 5px 0 0 5px;
}

.p-is-active {
    background: linear-gradient(180deg, #32cd32 0, #3caa62)!important;
    color: #fff!important;
}

.p-tab {
    margin: 0;
    padding: 5px 35px;
    background: #fff;
    color: #333230;
    text-decoration: none;
    border: 1px solid #cacaca;
    display: inline-block;
    font-size: 17px;
    font-family: -apple-system, "Inter", sans-serif;
    cursor: pointer;
}

.p-tabs> :last-child {
    border-radius: 0 5px 5px 0;
}

.small-radio {
    padding: 2px 8px;
    color: #000;
}

.small-radio.primary.selected {
    border-radius: 10px;
    background-color: rgba(3, 169, 244, .3);
}

.small-radio.success.selected {
    border-radius: 10px;
    background-color: rgba(139, 195, 74, .3)
}

.small-radio.danger.selected {
    border-radius: 10px;
    background-color: rgba(244, 67, 54, .3)
}

.small-radio>input+span {
    height: 15px;
    width: 15px;
}

.p-form-radio-cont,
.p-form-checkbox-cont {
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    margin: 0 10px;
    user-select: none;
}

.p-form-checkbox-cont span {
    margin-right: 5px;
}

a {
    color: #3CAA62!important;
}

.dx-list-search {
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

.list_caption {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.list_description {
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
}

.dx-switch-on-value .dx-switch-container::before {
    background-color: #6CD78B!important;
}

.dx-switch-on-value .dx-switch-handle::before {
    background-color: #3CAA62!important;
}

.rg-number-celleditor input {
    border: 0;
}