.dx-button-mode-text.dx-button-success {
    color: #3CAA5F !important;
}

.dx-button-mode-text.dx-button-success .dx-icon {
    color: #3CAA5F !important;
}

.dx-button-has-icon.dx-button-has-text.dx-button-mode-text .dx-icon {
    font-size: 18px!important;
}

.dx-datagrid-rowsview .dx-row.dx-group-row td {
    border-left-color: #e0e0e0 !important;
    border-right-color: #e0e0e0 !important;
}

.dx-datagrid .dx-row>td {
    font-size: 12px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-rowsview {
    border-left: 0px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-headers {
    border-left: 0px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-filter-panel {
    border-left: 0px !important;
}

.table-noborder-left .dx-datagrid-filter-panel {
    border-bottom: 0px !important;
}

.dx-datagrid-rowsview .dx-row>td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.dx-datagrid-headers .dx-row:not(.dx-header-row)>td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.dx-datagrid-headers .dx-header-row>td {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    font-weight: bold !important;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input:not(.dx-texteditor-input-auto-resize) {
    height: 20px !important;
    line-height: 1.2rem !important;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
    height: 20px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 0px !important;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)>td:not(.dx-focused) {
    background-color: rgba(0, 0, 0, .04);
}

.dx-datagrid {
    background-color: transparent !important;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
    width: 36px !important;
    min-width: 36px !important;
    padding-left: 8px !important;
    padding-right: 10px !important;
}

.dx-checkbox-icon {
    border: 1px solid rgba(0, 0, 0, .2) !important;
    border-radius: 4px !important;
    width: 14px !important;
    height: 14px !important;
}

.dx-checkbox-checked .dx-checkbox-icon {
    color: #fff;
    background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
    border: none !important;
    font: 12px/12px DXIcons !important;
    text-align: center;
    box-shadow: none;
}

.dx-checkbox-checked .dx-checkbox-icon:before {
    margin-top: -6px !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    background: linear-gradient(to bottom, #b6e7a2 0%, #b6e7a2 100%);
    color: rgba(0, 0, 0, .87);
    /* border-left: 1px solid #FAFAFA!important;
      border-right: 1px solid #FAFAFA!important;
      border-bottom: 1px solid #FAFAFA!important;   */
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused) {
    background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
    color: #fff;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr:last-child>td {
    border-bottom: 1px solid #3caa62;
}

.cmd-edit-icon {
    display: none;
    cursor: pointer;
}

.dx-state-hover .cmd-edit-icon {
    display: inline;
}

.dx-row-focused .cmd-edit-icon {
    /* display: inline; */
    color: white;
}

.dx-row-focused a {
    /* display: inline; */
    color: white !important;
}

.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
    background: linear-gradient(to bottom, #b6e7a2 0%, #b6e7a2 100%);
    color: rgba(0, 0, 0, .87) !important;
}

.dx-datagrid-filter-row {
    background-color: transparent !important;
}

.dx-datagrid-filter-row input {
    padding-left: 20px !important;
}

.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
    background-color: transparent !important;
}

.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
    margin-top: -2px !important;
    margin-right: 2px;
}

.table-white-space td {
    white-space: pre-line;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
    color: #3CAA62 !important;
}

.dx-datagrid-filter-panel .dx-icon-filter {
    color: #3CAA62 !important;
}

.dx-datagrid .dx-header-filter:not(.dx-header-filter-empty),
.dx-datagrid-container .dx-header-filter:not(.dx-header-filter-empty) {
    color: #3CAA62 !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
    background-color: #3CAA62 !important;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
    background-color: #3CAA62 !important;
}