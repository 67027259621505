.my-dropdownbutton{
  display: inline-block;
  font-weight: 500;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: 6px;
  color: #333230;
  background-color: #fff;
  border-color: #565e64;
  border: 1px solid #cacaca;
  min-height: 26px!important;
}
.my-dropdownbutton-success{
  color: #fff;
    background: linear-gradient(180deg,#32cd32 0,#3caa62)!important;
    box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0 2px 3px -2px rgb(0 0 0 / 30%);
    border: 0!important;
    height: 26px!important;

}
.my-dropdownbutton .dx-button{
  border: none!important;
}
.my-dropdownbutton-success .dx-button{
  color: white!important;
}
.my-dropdownbutton .dx-button-text{
  text-transform: none!important;
  letter-spacing:normal!important
}
.my-dropdownbutton-success .dx-icon{
  color: white!important;
  font-size: 1rem!important;
}